var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"articles"},[_c('banner'),_c('div',{staticClass:"site-content animate"},[_c('div',{attrs:{"id":"article-menus"}},[_c('menu-tree',{attrs:{"menus":_vm.menus,"child-label":"child"}})],1),_c('main',{staticClass:"site-main"},[_c('article',{staticClass:"hentry"},[_vm._m(0),_c('div',{directives:[{name:"highlight",rawName:"v-highlight"}],staticClass:"entry-content"},[_c('p',[_vm._v("@[不懂it的小黔]")]),_c('h4',{attrs:{"id":"引言："}},[_vm._v("引言：")]),_c('p',[_vm._v("Lua 是一种轻量小巧的脚本语言，能为应用程序提供灵活的扩展和定制功能。")]),_c('h4',{attrs:{"id":"lua-应用场景"}},[_vm._v("Lua 应用场景")]),_vm._m(1),_c('hr'),_c('h4',{attrs:{"id":"安装"}},[_vm._v("安装")]),_vm._m(2),_c('hr'),_c('h4',{attrs:{"id":"安装过程可能出现的问题"}},[_vm._v("安装过程可能出现的问题")]),_vm._m(3),_c('hr'),_vm._m(4),_c('hr'),_c('h4',{attrs:{"id":"安装成功验证"}},[_vm._v("安装成功验证")]),_vm._m(5),_vm._m(6)]),_c('section-title',[_c('footer',{staticClass:"post-footer"},[_c('div',{staticClass:"post-like"},[_c('i',{staticClass:"iconfont iconeyes"}),_c('span',{staticClass:"count"},[_vm._v("12")])])])]),_vm._m(7),(false)?_c('div',{staticClass:"comments"},_vm._l((_vm.comments),function(item){return _c('comment',{key:item.comment.id,attrs:{"comment":item.comment}},[(item.reply.length)?_vm._l((item.reply),function(reply){return _c('comment',{key:reply.id,attrs:{"comment":reply}})}):_vm._e()],2)}),1):_vm._e()],1)])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('header',{staticClass:"entry-header"},[_c('h1',{staticClass:"entry-title"},[_vm._v("看一遍闭着眼都会安装Lua了")]),_c('hr'),_c('div',{staticClass:"breadcrumbs"},[_c('div',{attrs:{"id":"crumbs"}},[_vm._v("最后更新时间：2024年02月21日")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v("游戏开发")]),_c('li',[_vm._v("独立应用脚本")]),_c('li',[_vm._v("Web 应用脚本")]),_c('li',[_vm._v("扩展和数据库插件如：MySQL Proxy 和 MySQL WorkBench")]),_c('li',[_vm._v("安全系统，如入侵检测系统")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('pre',{staticClass:"lang-shell"},[_c('code',[_vm._v(" curl -R -O http://www.lua.org/ftp/lua-5.3.5.tar.gz #下载\n tar zxf lua-5.3.5.tar.gz #解压\n cd lua-5.3.3 #进入解压文件夹\n make linux test #安装&测试")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ol',[_c('li',[_vm._v("没有gcc命令(lua是C语言编写的，安装时依赖gcc) "),_c('blockquote',[_c('p',[_vm._v("使用"),_c('code',[_vm._v("which gcc")]),_vm._v("命令可以查看是否有gcc，如果没有使用下面命令进行安装gcc 与gcc-c++，")]),_c('pre',{staticClass:"lang-shell"},[_c('code',[_vm._v("yum -y install gcc\nyum -y install gcc-c++")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ol',{attrs:{"start":"2"}},[_c('li',[_vm._v("致命错误：readline/readline.h：没有那个文件或目录 "),_c('blockquote',[_c('p',[_vm._v("执行如下命令即可:")]),_c('pre',{staticClass:"lang-shell"},[_c('code',[_vm._v("yum install libtermcap-devel ncurses-devel libevent-devel readline-devel")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('blockquote',[_c('p',[_vm._v("执行"),_c('code',[_vm._v("lua -v")]),_vm._v(",出现如下信息代表安装成功"),_c('br'),_vm._v(" Lua 5.1.4 Copyright (C) 1994-2008 Lua.org, PUC-Rio")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('pre',{staticClass:"lang-shell"},[_c('code',[_vm._v("lua -v")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"open-message"},[_c('p',[_vm._v("声明：粤容易博客 | 版权所有")]),_c('p',[_vm._v("转载：转载请注明原文链接 - "),_c('a',{attrs:{"href":"/"}},[_vm._v("看一遍闭着眼都会安装Lua了")])])])
}]

export { render, staticRenderFns }